export interface IParamsShop {
  ShopName: string;
  Status: number;
  PageIndex: number;
  PageSize: number;
}

export interface IBodyCreateShop {
  ShopID: number;
  ShopName: string;
  Status: number;
  Address: string;
  Phone: string;
  Represent: string;
}

const shopActions = {
  GET_SHOP: "GET_SHOP",
  GET_SHOP_SUCCESS: "GET_SHOP_SUCCESS",
  GET_SHOP_ERROR: "GET_SHOP_ERROR",

  CREATE_SHOP: "CREATE_SHOP",
  CREATE_SHOP_SUCCESS: "CREATE_SHOP_SUCCESS",
  CREATE_SHOP_ERROR: "CREATE_SHOP_ERROR",

  UPDATE_SHOP: "UPDATE_SHOP",
  UPDATE_SHOP_SUCCESS: "UPDATE_SHOP_SUCCESS",
  UPDATE_SHOP_ERROR: "UPDATE_SHOP_ERROR",

  DELETE_SHOP: "DELETE_SHOP",
  DELETE_SHOP_SUCCESS: "DELETE_SHOP_SUCCESS",
  DELETE_SHOP_ERROR: "DELETE_SHOP_ERROR",

  getShop: () => ({
    type: shopActions.GET_SHOP,
  }),
  getShopSuccess: (result: any) => ({
    type: shopActions.GET_SHOP_SUCCESS,
    result,
  }),
  getShopError: (result: any) => ({
    type: shopActions.GET_SHOP_ERROR,
    result,
  }),

  createShop: (body: IBodyCreateShop, callback: any, message: string) => ({
    type: shopActions.CREATE_SHOP,
    body,
    callback,
    message,
  }),

  createShopSuccess: (result: any) => ({
    type: shopActions.CREATE_SHOP_SUCCESS,
    result,
  }),
  createShopError: (result: any) => ({
    type: shopActions.CREATE_SHOP_ERROR,
    result,
  }),

  updateShop: (body: IBodyCreateShop) => ({
    type: shopActions.UPDATE_SHOP,
    body,
  }),
  updateShopSuccess: (result: any) => ({
    type: shopActions.UPDATE_SHOP_SUCCESS,
    result,
  }),
  updateShopError: (result: any) => ({
    type: shopActions.UPDATE_SHOP_ERROR,
    result,
  }),

  deleteShop: (shopID: number) => ({
    type: shopActions.DELETE_SHOP,
    shopID,
  }),
  deleteShopSuccess: (result: any) => ({
    type: shopActions.DELETE_SHOP_SUCCESS,
    result,
  }),
  deleteShopError: () => ({
    type: shopActions.DELETE_SHOP_ERROR,
  }),
};

export default shopActions;
