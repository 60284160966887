import PropTypes from "prop-types";
import {forwardRef} from "react";
import {Helmet} from "react-helmet-async";
import {Box} from "@mui/material";

// ----------------------------------------------------------------------
//@ts-ignore
const Page = forwardRef(({title = "", children, ...other}, ref) => {
  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  //@ts-ignore
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
