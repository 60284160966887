import actions from "./actions";

const initState = {
  user: null,
  isLoading: false,
};

export default function authReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOGIN_SUCCESS:
      return {
        user: action.result,
        isLoading: false,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
