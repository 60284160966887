import {IBodyStaff, IParamsUser} from "../redux/user/actions";
import axiosInstance from "./axiosInstance";

const ApiUser = {
  getUsers(params: IParamsUser) {
    const endpoint = "User/AjaxLoadStaff";
    return axiosInstance.get(endpoint, {
      params: params,
    });
  },

  addOrUpdateUser(body: IBodyStaff) {
    const endpoint = "User/AddOrEditStaff";
    return axiosInstance.post(endpoint, body);
  },

  deleteUser(userID: number) {
    const endpoint = "User/DeleteStaff";
    return axiosInstance.get(endpoint, {
      params: {
        StaffID: userID,
      },
    });
  },
};
export default ApiUser;
