import axios, {AxiosRequestConfig} from "axios";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {baseURL} from "../services/api";
import {initAxiosConfig, serviceOptions} from "../services/serviceOptions";
import {getShop} from "../utils/utility";

export const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      if (!token) navigate("/login", {replace: true});
      else {
        //@ts-ignore
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
          localStorage.clear();
          navigate("/login", {replace: true});
        }
        // const axiosConfig: AxiosRequestConfig = {
        //   baseURL: baseURL,
        //   timeout: 60000, // 1 phút
        // };
        // const shopID = await getShop()?.ShopID;
        //
        // axiosConfig.headers = {
        //   Authorization: "Bearer " + token,
        //   "Access-Control-Allow-Origin": "*",
        //   UserID: user?.UserID,
        //   ShopID: shopID,
        // };
        //
        // axios.interceptors.response.use(
        //   (response) => response,
        //   (error) => {
        //     console.log("Error", error);
        //     // return Promise.reject(error);
        //   }
        // );
        //
        // serviceOptions.axios = axios.create(axiosConfig);
        initAxiosConfig();
      }
    };

    useEffect(() => {
      checkAuth();
    }, [location.pathname]);

    //@ts-ignore
    return <WrappedComponent {...props} />;
  };
};
