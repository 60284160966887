export const PATHNAME = {
  LOGIN: "/login",
  ROOT: "/",
  REGISTER: "/register",
  MANAGER_BRANCH: "/manager/branch",
  MANAGER_STAFF: "/manager/staff",
  MANAGER_PERMISSION: "/manager/permission",
  MANAGER_BANK: "/manager/bank",
  CREATE_STAFF: "manager/staff/create",
  CHANGE_PASSWORD: "changepassword",
  Collaborators: "/manager/collaborators",
  Customer: "/customer",
  Partner: "/manager/partner",
  CARD: "/cards",
  ThuChi: "/collect-expense",
  ConfigCollectExpense: "/config-collect-expense",
  Pos: "/manager/pos",
  Contract: "/contract",
  Debt: "/debt",
  Report: "/report",
  ReportDailyAdvancePayment: "/report/advance-payment",
  PostManager: "/manager/pospartner",
  Warning: "/warning",
  DebtCompare: "/debt/generalcomparison",
  DebtAdvancePayment: "/debt/advance-payment",
  ReportAllShop: "/report/reportallshop",
  Company: "https://ag.cardpro.ai/",
  SchedulePayment: "/schedule-payment"
}
