import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import viVN from "antd/lib/locale-provider/vi_VN";
import {ConfigProvider} from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <HelmetProvider>
      <ConfigProvider locale={viVN}>
        <Provider store={store}>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </HelmetProvider>
  </>
);
