import {Button, Checkbox, Form, Grid, Input, Space, Typography,} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "../../components/Page";
import {PATHNAME} from "../../routers/path";
import {UserService} from "../../services";
import {toastError} from "../../utils/toast";
import {serviceOptions} from "../../services/serviceOptions";
import axios, {AxiosRequestConfig} from "axios";
import {baseURL} from "../../services/api";

export default function Login() {
  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: baseURL,
      timeout: 60000, // 1 phút
    };
    serviceOptions.axios = axios.create(axiosConfig);
  }, []);

  const onFinish = (values: any) => {
    setIsLoading(true);
    UserService.login({
      body: {
        UserName: values.username,
        PlainPassword: values.password,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.Result === 1) {
          //@ts-ignore
          localStorage.setItem("groupId", response.Data?.GroupID);
          //@ts-ignore
          localStorage.setItem("token", response.Data?.Token);
          localStorage.setItem("user", JSON.stringify(response.Data));
          localStorage.setItem(
            "shop",
            JSON.stringify({
              ShopID: response.Data?.ShopID,
              ShopName: response.Data?.ShopName,
            })
          );
          const axiosConfig: AxiosRequestConfig = {
            baseURL: baseURL,
            timeout: 60000, // 1 phút
          };

          axiosConfig.headers = {
            Authorization: response?.Data?.Token != null? "Bearer " + response?.Data?.Token : "",
            // Token: response?.Data?.Token || "",
            "Access-Control-Allow-Origin": "*",
            UserID: response.Data?.UserID || "",
            ShopID: response.Data?.ShopID || "",
          };
          axios.interceptors.response.use(
            (response) => response,
            (error) => {
              console.log("Error", error);
            }
          );

          serviceOptions.axios = axios.create(axiosConfig);
          navigate("/", {replace: true});
        } else {
          toastError(response.Message);
        }
      })
      .catch((err) => {
        toastError(err);
        setIsLoading(false);
        toastError(err.message);
      });
  };

  return (
    //@ts-ignore
    <Page title="VDI - Đăng nhập">
      <div className="bg-[#F9F9F9] h-screen flex flex-col items-center justify-center">
        <img
          className="hidden lg:block h-[80px] object-fill mb-2"
          src={require("../../assets/images/logo.png")}
        />
        <img
          className="block lg:hidden h-[80px] object-fill mb-2"
          src={require("../../assets/images/logo.png")}
        />
        <div className="flex w-[448px] px-[40px] py-[40px] flex-col gap-[24px] bg-white rounded-[16px] shadow-md mt-6">
          <Typography.Text
            strong
            style={{
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            Đăng nhập vào hệ thống
          </Typography.Text>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Tên tài khoản là bắt buộc",
                },
              ]}
              label="Tài khoản"
              name={"username"}
            >
              <Input placeholder="Nhập tài khoản"/>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Mật khẩu là bắt buộc",
                },
              ]}
              label={"Mật khẩu"}
              name={"password"}
            >
              <Input.Password placeholder="Nhập mật khẩu"/>
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Ghi nhớ đăng nhập</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button
                loading={isLoading}
                htmlType="submit"
                type="primary"
                style={{
                  height: "48px",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                Đăng nhập
              </Button>
            </Form.Item>
            <div className="flex justify-center mt-6">
              <Space>
                <Typography.Text style={{color: "#A0AEC0"}}>
                  Bạn chưa có tài khoản?
                  <a href="/register"> Đăng ký ngay</a>
                </Typography.Text>
              </Space>
            </div>

            {/* <div className="flex justify-center">
              <Space>
                <Typography.Text style={{ color: "#A0AEC0" }}>
                  Vui lòng liên hệ hotline :{" "}
                  <a href="tel:0933656265"> 0933 65 62 65</a> để tạo tài khoản
                </Typography.Text>
              </Space>
            </div> */}
            <Button
              style={{
                borderRadius: "10px",
                marginTop: 40,
                width: "100%",
              }}
            >
              <a href={PATHNAME.Company}>Trang quản lý</a>
            </Button>

          </Form>
        </div>
      </div>
    </Page>
  );
}
