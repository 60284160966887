export const filterOption = (
  input: string,
  option: any,
) => {
  const inputValue = input
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
      .toLowerCase(),
    optionValue =
      typeof option?.children === 'string' ? option?.children : option?.title

  return (
    optionValue
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
      .toLowerCase()
      .indexOf(inputValue) >= 0
  )
}

export const DataCard_TypeCard = [
  {
    id: 2,
    name: "Visa"
  },
  {
    id: 3,
    name: "Master"
  },
  {
    id: 1,
    name: "JCB"
  },
  {
    id: 4,
    name: "Napas"
  },
]

export const formatMoney = (money: any) => {
  try {
    if (money) {
      money = money.toString().replace(/^0+|,|\.| /g, "")
      return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      return "0"
    }
  } catch (e) {
    return "0"
  }
}

export const ScheduleWork_Status = [
  {
    id: 1,
    name: "Chưa xử lý"
  },
  {
    id: 2,
    name: "Không phản hồi"
  },
  {
    id: 3,
    name: "Đã thông báo"
  },
  // {
  //     id: 4,
  //     name: "Đã xử lý 1 phần"
  // },
  {
    id: 5,
    name: "Đã thu phí"
  },
  {
    id: 7,
    name: "Đang xử lý"
  },
  {
    id: 6,
    name: "Nợ phí"
  },
  {
    id: -11,
    name: "Huỷ"
  },
  {
    id: 10,
    name: "Đã hoàn thành"
  }
]

export const GroupID = {
  ADMIN: -1,
  ACCOUNTANT: 1,
  EMPLOYEE: 2
}

export const GroupName = {
  ADMIN: "Quản trị viên",
  ACCOUNTANT: "Kế toán",
  EMPLOYEE: "Nhân viên"
}

export const StaffGroup = [
  {
    id: GroupID.ADMIN,
    name: GroupName.ADMIN
  },
  {
    id: GroupID.ACCOUNTANT,
    name: GroupName.ACCOUNTANT
  },
  {
    id: GroupID.EMPLOYEE,
    name: GroupName.EMPLOYEE
  }
]

export const renderStaffGroup = (groupId: number) => {
  switch (groupId) {
    case GroupID.ADMIN:
      return GroupName.ADMIN
    case GroupID.ACCOUNTANT:
      return GroupName.ACCOUNTANT
    case GroupID.EMPLOYEE:
      return GroupName.EMPLOYEE
  }
}

export const renderStatus = (status: number) => {
  switch (status) {
    case 1:
      return "Chưa xử lý"
    case 2:
      return "Không phản hồi"
    case 3:
      return "Đã thông báo"

    case 4:
      return "Đã xử lý"

    case 5:
      return "Đã thu phí"

    case 6:
      return "Nợ Phí"

    case 7:
      return "Đang xử lý"
    case -11:
      return "Huỷ"
    case 10:
      return "Đã hoàn thành"
    case 11:
      return "Đã xử lý"
  }
}

export const VNLocale = {
  lang: {
    locale: "vi_VN",
    today: "Hôm nay",
    now: "Bây giờ",
    backToToday: "Trở về hôm nay",
    ok: "OK",
    clear: "Xóa",
    month: "Tháng",
    year: "Năm",
    timeSelect: "Chọn thời gian",
    dateSelect: "Chọn ngày",
    weekSelect: "Chọn tuần",
    monthSelect: "Chọn tháng",
    yearSelect: "Chọn năm",
    decadeSelect: "Chọn thập kỷ",
    yearFormat: "YYYY",
    dateFormat: "D/M/YYYY",
    dayFormat: "D",
    dateTimeFormat: "D/M/YYYY HH:mm:ss",
    monthBeforeYear: true,
    previousMonth: "Tháng trước (PageUp)",
    nextMonth: "Tháng sau (PageDown)",
    previousYear: "Năm trước (Control + left)",
    nextYear: "Năm sau (Control + right)",
    previousDecade: "Thập kỷ trước",
    nextDecade: "Thập kỷ sau",
    previousCentury: "Thế kỷ trước",
    nextCentury: "Thế kỷ sau",
    shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
    shortMonths: [
      "Tháng 1",
      "Tháng 2",
      "Tháng 3",
      "Tháng 4",
      "Tháng 5",
      "Tháng 6",
      "Tháng 7",
      "Tháng 8",
      "Tháng 9",
      "Tháng 10",
      "Tháng 11",
      "Tháng 12",
    ],
  }
}

export const EnumMenu = [
  {
    id: 1,
    name: "Data thẻ"
  },
  {
    id: 2,
    name: "Đơn hàng"
  },
  {
    id: 3,
    name: "Thu chi"
  },
  {
    id: 4,
    name: "Đối tác quẹt"
  },
  {
    id: 5,
    name: "Công nợ"
  },
  {
    id: 6,
    name: "Tiền ứng"
  }
]

export const EnumAction = [
  {
    id: 1,
    name: "Thêm"
  },
  {
    id: 2,
    name: "Sửa"
  },
  {
    id: 3,
    name: "Xoá"
  },
]
