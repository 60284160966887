import actions, {IBodyCreateShop} from "./actions";

const initState = {
  isLoading: false,
  data: [],
  total: 0,
};

export default function shopReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.GET_SHOP:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.result.Data,
        total: action.result.Total,
      };
    case actions.GET_SHOP_ERROR:
      return {
        ...state,
        isLoading: false,
        data: [],
        total: 0,
      };
    case actions.CREATE_SHOP:
      return {
        ...state,
        isLoading: true,
      };
    case actions.CREATE_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.CREATE_SHOP_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.UPDATE_SHOP:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.UPDATE_SHOP_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DELETE_SHOP:
      return {
        ...state,
        isLoading: true,
      };
    case actions.DELETE_SHOP_SUCCESS:
      const {result} = action;
      const new_data = state.data.filter(
        (item: IBodyCreateShop) => item.ShopID !== result.ShopID
      );
      return {
        ...state,
        isLoading: false,
        data: new_data,
      };
    case actions.DELETE_SHOP_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
