import {all, call, put, takeEvery} from "redux-saga/effects";
import {UserService} from "../../services";
import Api from "../../services/api";
import {toastError, toastSuccess} from "../../utils/toast";
import actions from "./actions";

//@ts-ignore
function* login({body, navigate}) {
  try {
    //@ts-ignore
    // const response = {
    //   "data": {
    //     "Result": 1,
    //     "Message": "Đăng nhập thành công",
    //     "Total": 0,
    //     "TotalData": {},
    //     "Data": {
    //       "ShopID": 130658,
    //       "ShopName": "Cửa Hàng 1",
    //       "GroupID": 3,
    //       "lstShop": [
    //         {
    //           "ShopID": 130658,
    //           "ShopName": "Cửa Hàng 1",
    //           "Address": null
    //         }
    //       ],
    //       "UserID": 140834,
    //       "UserName": "quangbinh247",
    //       "FullName": "Quang Bình",
    //       "Phone": "0813498485",
    //       "ExpiredDate": "2024-10-15T09:34:42.427",
    //       "Token": "D9HvT2mBLKXzl7VDyHvzVQ==",
    //       "menuPermissions": [
    //         {
    //           "title": "DATA THẺ",
    //           "visible": true,
    //           "children": [],
    //           "path": "/cards",
    //           "position": 1
    //         },
    //         {
    //           "title": "ĐƠN HÀNG",
    //           "visible": true,
    //           "children": [],
    //           "path": "/contract",
    //           "position": 2
    //         },
    //         // {
    //         //   "title": "THU CHI",
    //         //   "visible": true,
    //         //   "children": [],
    //         //   "path": "/collect-expense",
    //         //   "position": 3
    //         // },
    //         // {
    //         //   "title": "ĐỐI TÁC QUẸT",
    //         //   "visible": true,
    //         //   "children": [],
    //         //   "path": "/contract/partner",
    //         //   "position": 3
    //         // },
    //         // {
    //         //   "title": "QUẢN LÝ",
    //         //   "visible": true,
    //         //   "children": [
    //         //     {
    //         //       "title": "CỘNG TÁC VIÊN",
    //         //       "visible": true,
    //         //       "children": [],
    //         //       "path": "/manager/collaborators",
    //         //       "position": 4
    //         //     },
    //         //     {
    //         //       "title": "ĐỐI TÁC ĐỐI ỨNG",
    //         //       "visible": true,
    //         //       "children": [],
    //         //       "path": "/partner/configfee",
    //         //       "position": 4
    //         //     }
    //         //   ],
    //         //   "path": "/manager",
    //         //   "position": 4
    //         // },
    //         // {
    //         //   "title": "CÔNG NỢ",
    //         //   "visible": true,
    //         //   "children": [
    //         //     {
    //         //       "title": "KHÁCH HÀNG ",
    //         //       "visible": true,
    //         //       "children": [],
    //         //       "path": "/debt/customer",
    //         //       "position": 5
    //         //     },
    //         //     {
    //         //       "title": "ĐỐI TÁC",
    //         //       "visible": true,
    //         //       "children": [],
    //         //       "path": "/debt/partner",
    //         //       "position": 5
    //         //     },
    //         //     {
    //         //       "title": "CTV",
    //         //       "visible": true,
    //         //       "children": [],
    //         //       "path": "/debt/aff",
    //         //       "position": 5
    //         //     }
    //         //   ],
    //         //   "path": "/debt/123123",
    //         //   "position": 5
    //         // },
    //         {
    //           "title": "BÁO CÁO",
    //           "visible": true,
    //           "children": [
    //             {
    //               "title": "DOANH THU",
    //               "visible": true,
    //               "children": [],
    //               "path": "/report/turnover",
    //               "position": 6
    //             },
    //             // {
    //             //   "title": "SỔ QUỸ",
    //             //   "visible": true,
    //             //   "children": [],
    //             //   "path": "/report/cashbook",
    //             //   "position": 6
    //             // },
    //             // {
    //             //   "title": "LỊCH SỬ THU TIỀN",
    //             //   "visible": true,
    //             //   "children": [],
    //             //   "path": "/report/historycustomerpay",
    //             //   "position": 6
    //             // },
    //             {
    //               "title": "BÁO CÁO NGÀY",
    //               "visible": true,
    //               "children": [],
    //               "path": "/report/reportdaily",
    //               "position": 6
    //             }
    //           ],
    //           "path": "/report",
    //           "position": 6
    //         },
    //         // {
    //         //   "title": "LỊCH SỬ THAO TÁC",
    //         //   "visible": true,
    //         //   "children": [],
    //         //   "path": "/history/logaction",
    //         //   "position": 10
    //         // },
    //         // {
    //         //   "title": "CHI PHÍ NỢ XẤU",
    //         //   "visible": true,
    //         //   "children": [],
    //         //   "path": "/expensebad/index",
    //         //   "position": 11
    //         // }
    //       ]
    //     }
    //   }
    // }

    const response = yield call(Api.login, body);
    if (response.data.Result === 1) {
      localStorage.setItem("token", response.data.Data.Token);
      localStorage.setItem("user", JSON.stringify(response.data.Data));
      localStorage.setItem(
        "shop",
        JSON.stringify({
          ShopID: response.data.Data?.ShopID,
          ShopName: response.data.Data?.ShopName,
        })
      );
      yield put(actions.loginSuccess(response.data.Data));
      navigate("/", {replace: true});
    } else {
      toastError(response.data.Message);
    }
  } catch (error) {
    toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

function* changePassword(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(Api.changePassword, payload.body);
    if (response.data.Result === 1) {
      localStorage.clear();
      yield put(actions.changePasswordSuccess(response.data.Data));
      toastSuccess("Đổi mật khẩu thành công");
      payload.navigate("/login", {replace: true});
    } else {
      toastError(response.data.Message);
    }
  } catch (error) {
    toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

function* register(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(UserService.register, payload.body);
    if (response.data.Result === 1) {
      localStorage.clear();
      yield put(actions.registerSuccess(response.data.Data));
      toastSuccess("Đăng ký thành công");
      payload.navigate("/login", {replace: true});
    } else {
      toastError(response.data.Message);
    }
  } catch (error) {
    toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

export default function* rootSaga() {
  //@ts-ignore
  yield all([takeEvery(actions.LOGIN, login)]);
  yield all([takeEvery(actions.CHANGE_PASSWORD, changePassword)]);
  yield all([takeEvery(actions.REGISTER, register)]);
}
