import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {createBrowserHistory as createHistory} from "history";
//@ts-ignore
import {routerMiddleware, routerReducer} from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import {loadingBarMiddleware, loadingBarReducer} from "react-redux-loading-bar";
import rootSaga from "./saga";
import reducers from "./reducer";

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [
  sagaMiddleware,
  routeMiddleware,
  // loadingBarMiddleware({
  //   promiseTypeSuffixes: ["REQUEST", "SUCCESS", "FAILURE"],
  // }), // manages loading bar
];

let enhancers = [applyMiddleware(...middlewares)];
const store = createStore(
  combineReducers({
    reducers,
    router: routerReducer,
    // loadingBar: loadingBarReducer,
  }),
  compose(...enhancers)
);

sagaMiddleware.run(rootSaga);

export {store, history};
