import {IBodyCreateShop, IParamsShop} from "../redux/shop/actions";
import axiosInstance from "./axiosInstance";

const ApiShop = {
  getShop(params: IParamsShop) {
    const endpoint = "Shop/GetShopActive";
    return axiosInstance.get(endpoint, {
      params: params,
    });
  },

  addOrUpdateShop(body: IBodyCreateShop) {
    const endpoint = "Shop/AddOrEdit";
    return axiosInstance.post(endpoint, body);
  },

  deleteShop(shopID: number) {
    const endpoint = "Shop/Delete";
    return axiosInstance.get(endpoint, {
      params: {
        ShopIDDelete: shopID,
      },
    });
  },
};

export default ApiShop;
