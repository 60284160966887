import React from "react";
import "./App.css";
import Router from "./routers";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ConfigProvider} from "antd";

function App() {
  return (
    <ConfigProvider>
      <Router/>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </ConfigProvider>
  );
}

export default App;
