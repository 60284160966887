import {all, call, delay, put, takeEvery} from "redux-saga/effects";
import ApiShop from "../../services/shop";
import {toastError, toastSuccess} from "../../utils/toast";
import actions from "./actions";
import {ShopService} from "../../services";

function* getShop() {
  try {
    console.log("call");
    //@ts-ignore
    const response = yield call(ShopService.getShopActive);
    console.log(response);

    if (response.Result === 1) {
      yield put(actions.getShopSuccess(response.Data));
    } else {
      toastError(response.Message);
    }
  } catch (error) {
    yield delay(1000);
    // toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
    console.log(error);
  }
}

function* createShop(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(ApiShop.addOrUpdateShop, payload.body);
    if (response.data.Result === 1) {
      // yield delay(1000);
      yield put(actions.createShopSuccess(response.data));
      toastSuccess(payload.message);
      yield put(payload.callback());
    } else {
      toastError(response.data.Message);
      yield put(actions.createShopError(""));
    }
  } catch (error) {
    // yield delay(1000);
    yield put(actions.createShopError(error));
    // toastError("Có lỗi xảy ra! Vui lòng liên hệ Admin");
  }
}

function* deleteShop(payload: any) {
  try {
    //@ts-ignore
    const response = yield call(ApiShop.deleteShop, payload.shopID);
    if (response.data.Result === 1) {
      // yield delay(1000);
      yield put(actions.deleteShopSuccess({ShopID: payload.shopID}));
      toastSuccess("Xóa cửa hàng thành công");
    } else {
      toastError(response.data.Message);
      yield put(actions.deleteShopError());
    }
  } catch (error) {
    console.log(error);
    yield put(actions.deleteShopError());
  }
}

export default function* rootSaga() {
  //@ts-ignore
  yield all([takeEvery(actions.GET_SHOP, getShop)]);
  //@ts-ignore
  yield all([takeEvery(actions.CREATE_SHOP, createShop)]);
  //@ts-ignore
  yield all([takeEvery(actions.DELETE_SHOP, deleteShop)]);
}
