import {toast} from "react-toastify"

const toastError = (message: string | undefined) => {
  toast.error(message)
}

const toastSuccess = (message: string) => {
  toast.success(message)
}

export {
  toastError, toastSuccess
}