import {IBodyChangePassword, IBodyLogin, IBodyRegister} from "../redux/auth/actions";
import axiosInstance from "./axiosInstance";

// "http://api.vncash.vn"
// export const baseURL = "http://apitest.vncash.vn";
export const baseURL = "https://thisisjustamadeupurl.com";

const Api = {
  login(body: IBodyLogin) {
    const endpoint = "User/Login";
    return axiosInstance.post(endpoint, body);
  },

  changePassword(body: IBodyChangePassword) {
    const endpoint = "User/ChangePassword";
    return axiosInstance.post(endpoint, body);
  },

  register(body: IBodyRegister) {
    const endpoint = "User/Register";
    return axiosInstance.post(endpoint, body);
  },
};

export default Api;
